#portfolio-modal {
  height: auto;
  max-height: calc(100% - 40px);

  h3 {
    font-weight: 500;
    margin-bottom: 24px;
  }

  .portfolio-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .portfolio-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .portfolio-piece {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 16px;

      .portfolio-image {
        justify-self: center;
        width: 100%;
        max-width: 800px;
        border: 1px solid #bbb;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 8px;

        &.tall {
          width: 60%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .portfolio-image-description {
        width: 100%;
        text-align: center;
        font-weight: 600;
      }
    }
  }
}
