@import '../libs/mixins';

// MSIE: Required for IEMobile.
@-ms-viewport {
  width: device-width;
}

// MSIE: Prevents scrollbar from overlapping content.
body {
  -ms-overflow-style: scrollbar;
}

// Ensures page width is always >=320px.
@include breakpoint(xsmall) {
  html,
  body {
    min-width: 320px;
  }
}

body {
  background: _palette(bg-alt);

  // Prevents animation/transition "flicker" on page load.
  // Automatically added/removed by js/main.js.
  &.is-loading {
    *,
    *:before,
    *:after {
      @include vendor('animation', 'none !important');
      @include vendor('transition', 'none !important');
    }
  }
}

.bevel {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}

.rule {
  height: 1px;
  @extend .bevel;
  margin: 40px 0;
  position: relative;

  &::before {
    font-family: 'fontastic' !important;
    font-size: 2rem;
    position: absolute;
    left: 50%;
    background-color: #fff;
    width: 100px;
    text-align: center;
    margin-left: -50px;
    top: -28px;
  }

  &.rule-display {
    &::before {
      content: '\e03b';
    }
  }

  &.rule-params {
    &::before {
      content: '\e04b';
    }
  }

  &.rule-location {
    &::before {
      content: '\e043';
    }
  }

  &.rule-like {
    &::before {
      content: '\e042';
    }
  }

  &.rule-paperplane {
    &::before {
      content: '\e04a';
    }
  }

  &.rule-pen {
    &::before {
      content: '\e04c';
    }
  }

  &.rule-photo {
    &::before {
      content: '\e04e';
    }
  }

  &.rule-calendar {
    &::before {
      content: '\e033';
    }
  }

  &.rule-lab {
    &::before {
      content: '\e041';
    }
  }

  &.rule-book {
    &::before {
      content: '\79';
    }
  }

  &.rule-clock {
    &::before {
      content: '\51';
    }
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

#root {
  height: 100%;
  overflow-y: auto;
}
