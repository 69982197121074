// About page (/about)

.markdown {
  p {
    margin: 0 auto 16px;
  }

  li p {
    margin: 0 auto 8px;
  }

  h1 {
    font-size: 0.8em;
    margin-top: 3em;
  }
}
