@import '../../styles/css/libs/functions';
@import '../../styles/css/libs/vars';
@import '../../styles/css/libs/skel';

#sidebar {
  margin-right: _size(section-spacing);
  min-width: 22em;
  width: 22em;

  > * {
    border-top: solid 1px _palette(border);
    margin: _size(section-spacing) 0 0 0;
    padding: _size(section-spacing) 0 0 0;
  }

  > :first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .blurb {
    h2 {
      font-size: 0.8em;
      margin: 0 0 (_size(element-margin) * 0.75) 0;
    }

    h3 {
      font-size: 0.7em;
    }

    p {
      font-size: 0.9em;
    }
  }

  @include breakpoint(large) {
    border-top: solid 1px _palette(border);
    margin: _size(section-spacing) 0 0 0;
    min-width: 0;
    padding: _size(section-spacing) 0 0 0;
    width: 100%;
  }
}
