///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

.icon {
  @include icon;
  border-bottom: none;
  position: relative;

  > .label {
    display: none;
  }

  &.prefix {
  }

  &.suffix {
    &:before {
      float: right;
    }
  }
}

// @charset "UTF-8";

@font-face {
  font-family: 'fontastic';
  src: url('../../fonts/fontastic.eot');
  src: url('../../fonts/fontastic.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/fontastic.woff') format('woff'),
    url('../../fonts/fontastic.ttf') format('truetype'),
    url('../../fonts/fontastic.svg#fontastic') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'fontastic' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  // line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontastic' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  // line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-align-center:before {
  content: '\61';
}
.icon-align-justify:before {
  content: '\62';
}
.icon-align-left:before {
  content: '\63';
}
.icon-align-right:before {
  content: '\64';
}
.icon-archive:before {
  content: '\65';
}
.icon-arrow-down:before {
  content: '\66';
}
.icon-arrow-left:before {
  content: '\67';
}
.icon-arrow-right:before {
  content: '\68';
}
.icon-arrow-up:before {
  content: '\69';
}
.icon-atom:before {
  content: '\6a';
}
.icon-bag:before {
  content: '\6b';
}
.icon-bank-notes:before {
  content: '\6c';
}
.icon-barbell:before {
  content: '\6d';
}
.icon-bars:before {
  content: '\6e';
}
.icon-battery-0:before {
  content: '\6f';
}
.icon-battery-1:before {
  content: '\70';
}
.icon-battery-2:before {
  content: '\71';
}
.icon-battery-3:before {
  content: '\72';
}
.icon-battery-4:before {
  content: '\73';
}
.icon-battery-power:before {
  content: '\74';
}
.icon-beer:before {
  content: '\75';
}
.icon-bolt:before {
  content: '\76';
}
.icon-bones:before {
  content: '\77';
}
.icon-book-close:before {
  content: '\78';
}
.icon-book-open:before {
  content: '\79';
}
.icon-bookmark:before {
  content: '\7a';
}
.icon-box:before {
  content: '\41';
}
.icon-browser:before {
  content: '\42';
}
.icon-bubble:before {
  content: '\43';
}
.icon-bubble-1:before {
  content: '\44';
}
.icon-bubble-2:before {
  content: '\45';
}
.icon-bubble-3:before {
  content: '\46';
}
.icon-bucket:before {
  content: '\47';
}
.icon-calculator:before {
  content: '\48';
}
.icon-calendar:before {
  content: '\49';
}
.icon-camera:before {
  content: '\4a';
}
.icon-cardiac-pulse:before {
  content: '\4b';
}
.icon-cd:before {
  content: '\4c';
}
.icon-character:before {
  content: '\4d';
}
.icon-checkbox-checked:before {
  content: '\4e';
}
.icon-checkmark:before {
  content: '\4f';
}
.icon-clipboard:before {
  content: '\50';
}
.icon-clock:before {
  content: '\51';
}
.icon-cloud:before {
  content: '\52';
}
.icon-coffee:before {
  content: '\53';
}
.icon-comment:before {
  content: '\54';
}
.icon-connection-0:before {
  content: '\55';
}
.icon-connection-1:before {
  content: '\56';
}
.icon-connection-2:before {
  content: '\57';
}
.icon-connection-3:before {
  content: '\58';
}
.icon-connection-4:before {
  content: '\59';
}
.icon-credit-cards:before {
  content: '\5a';
}
.icon-crop:before {
  content: '\30';
}
.icon-cube:before {
  content: '\31';
}
.icon-diamond:before {
  content: '\32';
}
.icon-download:before {
  content: '\33';
}
.icon-email:before {
  content: '\34';
}
.icon-email-plane:before {
  content: '\35';
}
.icon-enter:before {
  content: '\36';
}
.icon-envelope:before {
  content: '\37';
}
.icon-eyedropper:before {
  content: '\38';
}
.icon-file:before {
  content: '\39';
}
.icon-file-add:before {
  content: '\21';
}
.icon-file-broken:before {
  content: '\22';
}
.icon-file-settings:before {
  content: '\23';
}
.icon-files:before {
  content: '\24';
}
.icon-flag:before {
  content: '\25';
}
.icon-folder:before {
  content: '\26';
}
.icon-folder-add:before {
  content: '\27';
}
.icon-folder-check:before {
  content: '\28';
}
.icon-folder-settings:before {
  content: '\29';
}
.icon-forbidden:before {
  content: '\2a';
}
.icon-frames:before {
  content: '\2b';
}
.icon-glass:before {
  content: '\2c';
}
.icon-graph:before {
  content: '\2d';
}
.icon-grid:before {
  content: '\2e';
}
.icon-heart:before {
  content: '\2f';
}
.icon-home:before {
  content: '\3a';
}
.icon-id-card:before {
  content: '\3b';
}
.icon-invoice:before {
  content: '\3c';
}
.icon-ipad:before {
  content: '\3d';
}
.icon-ipad-2:before {
  content: '\3e';
}
.icon-lab:before {
  content: '\3f';
}
.icon-laptop:before {
  content: '\40';
}
.icon-list:before {
  content: '\5b';
}
.icon-lock:before {
  content: '\5d';
}
.icon-locked:before {
  content: '\5e';
}
.icon-map:before {
  content: '\5f';
}
.icon-measure:before {
  content: '\60';
}
.icon-meter:before {
  content: '\7b';
}
.icon-micro:before {
  content: '\7c';
}
.icon-micro-mute:before {
  content: '\7d';
}
.icon-microwave:before {
  content: '\7e';
}
.icon-minus:before {
  content: '\5c';
}
.icon-modem:before {
  content: '\e000';
}
.icon-mute:before {
  content: '\e001';
}
.icon-newspaper:before {
  content: '\e002';
}
.icon-paperclip:before {
  content: '\e003';
}
.icon-pencil:before {
  content: '\e004';
}
.icon-phone:before {
  content: '\e005';
}
.icon-phone-2:before {
  content: '\e006';
}
.icon-phone-3:before {
  content: '\e007';
}
.icon-picture:before {
  content: '\e008';
}
.icon-pie-chart:before {
  content: '\e009';
}
.icon-pill:before {
  content: '\e00a';
}
.icon-pin:before {
  content: '\e00b';
}
.icon-power:before {
  content: '\e00c';
}
.icon-printer-1:before {
  content: '\e00d';
}
.icon-printer-2:before {
  content: '\e00e';
}
.icon-refresh:before {
  content: '\e00f';
}
.icon-reload:before {
  content: '\e010';
}
.icon-screen:before {
  content: '\e011';
}
.icon-select:before {
  content: '\e012';
}
.icon-set:before {
  content: '\e013';
}
.icon-settings:before {
  content: '\e014';
}
.icon-share:before {
  content: '\e015';
}
.icon-share-outline:before {
  content: '\e016';
}
.icon-shorts:before {
  content: '\e017';
}
.icon-speaker:before {
  content: '\e018';
}
.icon-star:before {
  content: '\e019';
}
.icon-stopwatch:before {
  content: '\e01a';
}
.icon-sun:before {
  content: '\e01b';
}
.icon-syringe:before {
  content: '\e01c';
}
.icon-tag:before {
  content: '\e01d';
}
.icon-train:before {
  content: '\e01e';
}
.icon-trash:before {
  content: '\e01f';
}
.icon-unlocked:before {
  content: '\e020';
}
.icon-user:before {
  content: '\e021';
}
.icon-user-outline:before {
  content: '\e022';
}
.icon-users:before {
  content: '\e023';
}
.icon-users-outline:before {
  content: '\e024';
}
.icon-volume:before {
  content: '\e025';
}
.icon-volume-down:before {
  content: '\e026';
}
.icon-volume-up:before {
  content: '\e027';
}
.icon-wifi-1:before {
  content: '\e028';
}
.icon-wifi-2:before {
  content: '\e029';
}
.icon-wifi-3:before {
  content: '\e02a';
}
.icon-window-delete:before {
  content: '\e02b';
}
.icon-windows:before {
  content: '\e02c';
}
.icon-zoom:before {
  content: '\e02d';
}
.icon-zoom-in:before {
  content: '\e02e';
}
.icon-zoom-out:before {
  content: '\e02f';
}
.icon-banknote:before {
  content: '\e030';
}
.icon-bubble-4:before {
  content: '\e031';
}
.icon-bulb:before {
  content: '\e032';
}
.icon-calendar-1:before {
  content: '\e033';
}
.icon-camera-1:before {
  content: '\e034';
}
.icon-clip:before {
  content: '\e035';
}
.icon-clock-1:before {
  content: '\e036';
}
.icon-cloud-1:before {
  content: '\e037';
}
.icon-cup:before {
  content: '\e038';
}
.icon-data:before {
  content: '\e039';
}
.icon-diamond-1:before {
  content: '\e03a';
}
.icon-display:before {
  content: '\e03b';
}
.icon-eye:before {
  content: '\e03c';
}
.icon-fire:before {
  content: '\e03d';
}
.icon-food:before {
  content: '\e03e';
}
.icon-heart-1:before {
  content: '\e03f';
}
.icon-key:before {
  content: '\e040';
}
.icon-lab-1:before {
  content: '\e041';
}
.icon-like:before {
  content: '\e042';
}
.icon-location:before {
  content: '\e043';
}
.icon-lock-1:before {
  content: '\e044';
}
.icon-mail:before {
  content: '\e045';
}
.icon-megaphone:before {
  content: '\e046';
}
.icon-music:before {
  content: '\e047';
}
.icon-news:before {
  content: '\e048';
}
.icon-note:before {
  content: '\e049';
}
.icon-paperplane:before {
  content: '\e04a';
}
.icon-params:before {
  content: '\e04b';
}
.icon-pen:before {
  content: '\e04c';
}
.icon-phone-1:before {
  content: '\e04d';
}
.icon-photo:before {
  content: '\e04e';
}
.icon-search:before {
  content: '\e04f';
}
.icon-settings-1:before {
  content: '\e050';
}
.icon-shop:before {
  content: '\e051';
}
.icon-sound:before {
  content: '\e052';
}
.icon-stack:before {
  content: '\e053';
}
.icon-star-1:before {
  content: '\e054';
}
.icon-study:before {
  content: '\e055';
}
.icon-t-shirt:before {
  content: '\e056';
}
.icon-tag-1:before {
  content: '\e057';
}
.icon-trash-1:before {
  content: '\e058';
}
.icon-truck:before {
  content: '\e059';
}
.icon-tv:before {
  content: '\e05a';
}
.icon-user-1:before {
  content: '\e05b';
}
.icon-vallet:before {
  content: '\e05c';
}
.icon-video:before {
  content: '\e05d';
}
.icon-vynil:before {
  content: '\e05e';
}
.icon-world:before {
  content: '\e05f';
}
