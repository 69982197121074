@import '../../styles/css/libs/functions';
@import '../../styles/css/libs/vars';
@import '../../styles/css/libs/skel';

.modal-overlay {
  align-items: center;
  display: flex;
  height: 100%;
  top: 0;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  touch-action: none;
  width: 100%;
  z-index: 1070;

  .modal-bg {
    background: rgba(#000, 0.2);
    display: flex;
    height: 100%;
    pointer-events: none;
    position: fixed;
    touch-action: none;
    width: 100%;
  }

  .modal {
    box-shadow: 0px 2px 20px rgba(#fff, 0.25);
    background: #fff;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    position: relative;
    touch-action: auto;
    z-index: 1080;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    max-width: 800px;

    .modal-header {
      font-size: 18px;
      padding: 16px 24px;
      align-items: center;
      color: #3c3b3b;
      font-family: 'Raleway', Helvetica, sans-serif;
      font-weight: 800;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      display: flex;

      > div:first-child {
        flex: 1;
      }

      .modal-close {
        .close {
          display: block;
          height: 24px;
          width: 24px;
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;

          svg {
            path,
            circle {
              fill: rgba(#000, 0.6);
            }
          }

          &:hover {
            svg {
              path,
              circle {
                fill: rgba(#000, 0.9);
              }
            }
          }
        }
      }
    }

    .modal-body {
      font-size: 16px;
      flex: 1;
      overflow-y: auto;
      padding: 24px;
      background-color: #fafafa;
      border-top: solid 1px _palette(border);
      border-bottom: solid 1px _palette(border);
    }

    .modal-footer {
      padding: 16px 24px;
      text-align: right;
      white-space: nowrap;

      .btn + .btn {
        margin-left: 16px;
      }

      &.center {
        text-align: center;
      }
    }
  }
}
