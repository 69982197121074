/// Makes an element's :before pseudoelement a FontAwesome icon.
/// @param {string} $content Optional content value to use.
/// @param {string} $where Optional pseudoelement to target (before or after).
@mixin icon($content: false, $where: before) {
  text-decoration: none;
  font-size: 24px;
}

/// Applies padding to an element, taking the current element-margin value into account.
/// @param {mixed} $tb Top/bottom padding.
/// @param {mixed} $lr Left/right padding.
/// @param {list} $pad Optional extra padding (in the following order top, right, bottom, left)
/// @param {bool} $important If true, adds !important.
@mixin padding($tb, $lr, $pad: (0, 0, 0, 0), $important: null) {
  @if $important {
    $important: '!important';
  }

  padding: ($tb + nth($pad, 1)) ($lr + nth($pad, 2))
    max(0.1em, $tb - _size(element-margin) + nth($pad, 3)) ($lr + nth($pad, 4))
    #{$important};
}

/// Encodes a SVG data URL so IE doesn't choke (via codepen.io/jakob-e/pen/YXXBrp).
/// @param {string} $svg SVG data URL.
/// @return {string} Encoded SVG data URL.
@function svg-url($svg) {
  $svg: str-replace($svg, '"', "'");
  $svg: str-replace($svg, '<', '%3C');
  $svg: str-replace($svg, '>', '%3E');
  $svg: str-replace($svg, '&', '%26');
  $svg: str-replace($svg, '#', '%23');
  $svg: str-replace($svg, '{', '%7B');
  $svg: str-replace($svg, '}', '%7D');
  $svg: str-replace($svg, ';', '%3B');

  @return url('data:image/svg+xml;charset=utf8,#{$svg}');
}

@mixin transitionTiming() {
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -moz-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -o-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

@mixin box-shadow(
  $top: 1px,
  $left: 1px,
  $blur: 2px,
  $color: rgba(#000, 0.1),
  $inset: false
) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin box-shadow-none() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
